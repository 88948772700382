import { Layout } from '../../shared/components/layout/Layout';
import {
  AtSymbolIcon,
  ChevronRightIcon,
  LockClosedIcon,
  TruckIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { css } from '@emotion/css';
import { Colors } from '../../shared/styles/colors';
import {
  h2Style,
  h3Style,
  p2Style,
  valueStyle,
} from '../../shared/styles/text';
import { useNavigator } from '../../routing/navigator';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { authenticationStore } from '../../shared/stores/authentification-store';
import { useCurrentUser } from '../../shared/services/api/user';
import { useQueryClient } from 'react-query';
import { CacheKeys } from '../../shared/services/api/cache-configuration';
import { Divider, DividerVariantEnum } from '../../shared/components/Divider';
import { useUser } from '../../shared/providers/user-provider';
import { UserRole } from '../../shared/types/enums';

export const AccountPage = (): JSX.Element => {
  const user = useUser()!;
  const navigator = useNavigator();
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  const handleUserLogout = async () => {
    window.localStorage.removeItem('token');
    authenticationStore.reset();
    await queryClient.invalidateQueries(CacheKeys.ME);
    await currentUser.refetch();
    navigator.toLanding();
  };

  return (
    <Layout.Content className={accountPageStyle}>
      <div className={accountPageHeaderContainerStyle}>
        <h1 className={accountPageHeaderStyle}>{user?.name}</h1>
        <Button
          size={ButtonSizeEnum.MEDIUM}
          variant={ButtonVariantEnum.TEXT}
          text={'logout'}
          onClick={handleUserLogout}
        />
      </div>
      <div className={accountEmailStyle}>{user?.email}</div>
      <div className={accountEmailStyle}>{user?.username}</div>
      {user.charter && user.role !== UserRole.CHARTER_OWNER && (
        <div className={charterInfoStyle}>
          <div>Charter info:</div>
          <div>{user?.charter?.owner.name}</div>
          <div>{user?.charter?.owner.email}</div>
        </div>
      )}
      <ul className={accountOptionsListStyle}>
        {user.role === UserRole.CHARTER_OWNER && (
          <>
            <li onClick={navigator.toPersonnelPage}>
              <UserGroupIcon className={iconStyle} />
              <span>Personnel</span>
              <ChevronRightIcon className={chevronRightStyle} />
            </li>
            <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
          </>
        )}
        <li onClick={navigator.toPersonalInformation}>
          <UserIcon className={iconStyle} />
          <span>Personal Information</span>
          <ChevronRightIcon className={chevronRightStyle} />
        </li>
        <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
        {user?.email && (
          <li onClick={navigator.toChangeEmail}>
            <AtSymbolIcon className={iconStyle} />
            <span>Change Email</span>
            <ChevronRightIcon className={chevronRightStyle} />
          </li>
        )}
        {user?.username && (
          <li onClick={navigator.toChangeUsername}>
            <AtSymbolIcon className={iconStyle} />
            <span>Change Username</span>
            <ChevronRightIcon className={chevronRightStyle} />
          </li>
        )}
        <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
        <li onClick={navigator.toPasswordPage}>
          <LockClosedIcon className={iconStyle} />
          <span>Change Password</span>
          <ChevronRightIcon className={chevronRightStyle} />
        </li>
        <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
        <li onClick={navigator.toCompanyInvoiceInformation}>
          <AtSymbolIcon className={iconStyle} />
          <span>Change Company Invoice Information</span>
          <ChevronRightIcon className={chevronRightStyle} />
        </li>
        <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
        <li onClick={navigator.toVehicles}>
          <TruckIcon className={iconStyle} />
          <span>Vehicles</span>
          <ChevronRightIcon className={chevronRightStyle} />
        </li>
      </ul>

      <span className={h3Style}>Legal</span>

      <ul className={legalOptionsListStyle}>
        <li onClick={navigator.toPrivacyPolicy}>
          <span>Privacy Policy</span>
        </li>
        <li onClick={navigator.toTermsOfUse}>
          <span>Terms of Use</span>
        </li>
        <li onClick={navigator.toTermsOfSale}>
          <span>Terms of Sale</span>
        </li>
      </ul>
    </Layout.Content>
  );
};

const accountPageStyle = css`
  padding-top: 0.625rem;
`;

const accountPageHeaderContainerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const accountPageHeaderStyle = css`
  ${h2Style}
  line-height:180%;
`;
const charterInfoStyle = css`
  ${p2Style}
  color:${Colors.WINDOW_GRAY};
  line-height: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.1875rem;
`;

const accountEmailStyle = css`
  ${p2Style}
  color:${Colors.WINDOW_GRAY};
  line-height: 100%;
`;

const accountOptionsListStyle = css`
  padding-top: 2rem;
  padding-bottom: 2rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  li {
    ${valueStyle}
    display: flex;
    align-content: center;
    align-items: center;
    padding: 1rem 0;
  }
`;

const iconStyle = css`
  size: 24px;
  width: 24px;
  height: 24px;
  color: ${Colors.WINDOW_GRAY};
  margin-right: 0.5rem;
`;

const chevronRightStyle = css`
  size: 24px;
  width: 24px;
  height: 24px;
  color: ${Colors.WINDOW_GRAY};
  margin-left: auto;
`;

const legalOptionsListStyle = css`
  padding-top: 0.5rem;
  margin-bottom: 6rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  li {
    ${valueStyle}
    display: flex;
    align-content: center;
    align-items: center;
    padding-top: 1rem;
  }
`;
