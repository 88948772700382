import { css } from '@emotion/css';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigator } from '../../../routing/navigator';
import { QuestionmarkCircle } from '../../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { Layout } from '../../../shared/components/layout/Layout';
import { useLocation } from '../../../shared/services/api/location';
import { handlePaymentSubmit } from '../../../shared/services/payment/payment';
import {
  BOTTOM_NAV_HEIGHT,
  HEADER_HEIGHT,
} from '../../../shared/styles/css-values';
import {
  doctrineStencilStyle,
  h1Style,
  p2Style,
} from '../../../shared/styles/text';
import { CurrencyAmountSelection } from '../../payment-page/CurrencyAmountSelection';
import { http } from '../../../shared/services/http';
import { useCurrentUser } from '../../../shared/services/api/user';

export const WalletAddFundsToLocationPage: React.FC = () => {
  const navigator = useNavigator();
  const { locationId } = useParams<{ locationId: string }>();
  const [amount, setAmount] = useState<string>('0');
  const [showMoreInfoRefund, setShowMoreInfoRefund] = useState<boolean>(false);
  const [isR1Invoice, setIsR1Invoice] = useState(false);

  const [proceedToPayWaiting, setProceedToPayWaiting] =
    useState<boolean>(false);
  const {
    data: user,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useCurrentUser();

  if (!locationId) throw new Error();

  const {
    data: location,
    isLoading,
    isError,
  } = useLocation({ id: locationId });

  if (isError || userIsError) {
    console.error({ isError, userIsError });
    return <p>Error</p>;
  }

  if (isLoading || !location || userIsLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Layout.Content>
      <div className={innerContainerStyle}>
        <div className={infoContainerStyle}>
          <h1>
            Add funds to your{' '}
            <span className={doctrineStencilStyle}>{location.name}</span>{' '}
            account.
          </h1>
        </div>
        <div>
          {showMoreInfoRefund && (
            <div>
              <a
                href="/terms-of-use#povrati-sredstava"
                style={{
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                }}
              >
                Payment and refund policy details
              </a>
            </div>
          )}
          <Button
            startIcon={<QuestionmarkCircle />}
            onClick={() => {
              setShowMoreInfoRefund(!showMoreInfoRefund);
            }}
            text={'More info'}
            size={ButtonSizeEnum.MEDIUM}
            variant={ButtonVariantEnum.TEXT}
          />
        </div>
        <CurrencyAmountSelection setAmount={setAmount} />
        {user?.r1CompanyAddress && user.r1CompanyName && user.r1VatNumber ? (
          <div className={r1InvoiceDivStyle}>
            <div className={r1InvoiceCheckboxRowStyle}>
              <label>I need company invoice.</label>
              <input
                className={checkboxStyle}
                type="checkbox"
                checked={isR1Invoice}
                onChange={() => setIsR1Invoice(!isR1Invoice)}
              />
            </div>
            {isR1Invoice && (
              <div className={r1InvoiceDataStyle}>
                <div className={r1InvoiceDataColumnStyle}>
                  <label>Name:</label>
                  <label>VAT number:</label>
                  <label>Address:</label>
                </div>
                <div className={r1InvoiceDataColumnStyle}>
                  <label>{user?.r1CompanyName}</label>
                  <label>{user?.r1VatNumber}</label>
                  <label>{user?.r1CompanyAddress}</label>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={r1InvoiceDivStyle}>
            If you need company invoice, please fill the required data in your
            profile.
          </div>
        )}
      </div>
      <div className={buttonActionContainerStyle}>
        <Button
          size={ButtonSizeEnum.LARGE}
          variant={ButtonVariantEnum.TEXT}
          text={'back'}
          onClick={navigator.toPrevious}
        />
        <Button
          size={ButtonSizeEnum.LARGE}
          variant={ButtonVariantEnum.FILLED}
          text={'proceed to pay'}
          onClick={async () => {
            if (!proceedToPayWaiting) {
              setProceedToPayWaiting(true);
              if (user) {
                const account = user.accounts?.find(
                  (a) => a.location.id === location.id,
                );
                if (!account) {
                  await http.createAccount(location.id);
                  setProceedToPayWaiting(false);
                }
              }
              await handlePaymentSubmit(amount, location.id, isR1Invoice);
              setProceedToPayWaiting(false);
            }
          }}
        />
      </div>
    </Layout.Content>
  );
};

const innerContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 1em;
  margin-top: calc(6.375rem - ${HEADER_HEIGHT});
  min-height: calc(100vh - 6.375rem - ${HEADER_HEIGHT} - ${BOTTOM_NAV_HEIGHT});
`;

const infoContainerStyle = css`
  h1 {
    ${h1Style}
  }
  p {
    ${p2Style}
    margin-top: 1rem;
  }
`;

const buttonActionContainerStyle = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const r1InvoiceDivStyle = css`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const r1InvoiceCheckboxRowStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const r1InvoiceDataStyle = css`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const r1InvoiceDataColumnStyle = css`
  display: flex;
  flex-direction: column;
`;

const checkboxStyle = css`
  transform: scale(1.2);
  width: 20px;
  height: 20px;
  margin: 5px;
  cursor: pointer;
`;
