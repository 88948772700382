/* eslint "@typescript-eslint/explicit-module-boundary-types": "off" */
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {
  CheckEmailParams,
  EditEmployeeParams,
  EmailVerificationParams,
  EmailSignInParams,
  NewEmployeeParams,
  SignUpParams,
  UpdateMeParams,
  UpdateMyEmailParams,
  UpdateMyPasswordParams,
  VerifyNewEmailParams,
  UsernameSignInParams,
  UpdateMyUsernameParams,
  UpdateMyCompanyInvoiceInformationParams,
} from '../../types/input-param-types';
import { User, Token } from '../../types/server-types';
import { http } from '../http';
import { CacheKeys } from './cache-configuration';

export const useEmailSignIn = () => {
  const { mutateAsync } = useMutation<Token, any, EmailSignInParams>(
    http.emailSignIn,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useUsernameSignIn = () => {
  const { mutateAsync } = useMutation<Token, any, UsernameSignInParams>(
    http.usernameSignIn,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useCheckIfEmailExists = () => {
  const { mutateAsync } = useMutation<boolean, any, CheckEmailParams>(
    http.checkIfEmailExists,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useSignUpUser = () => {
  const { mutateAsync } = useMutation<User, any, SignUpParams>(
    http.signUpUser,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useCurrentUser = () => {
  return useQuery(CacheKeys.ME, http.getCurrentUser);
};

export const useSendVerificationCode = () => {
  const { mutateAsync } = useMutation<Token, any, EmailVerificationParams>(
    http.sendVerificationCode,
    {
      onError: (error) => {
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useUpdateMe = () => {
  const { mutateAsync } = useMutation<any, UpdateMeParams, any>(http.updateMe, {
    onError: (error) => {
      throw error;
    },
  });
  return mutateAsync;
};

export const useUpdateMyPassword = () => {
  const { mutateAsync } = useMutation<any, UpdateMyPasswordParams, any>(
    http.updateMyPassword,
    {
      onError: (error) => {
        console.log('error' + error);
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useUpdateMyEmail = () => {
  const { mutateAsync } = useMutation<any, UpdateMyEmailParams, any>(
    http.updateMyEmail,
    {
      onError: (error) => {
        console.log('error' + error);
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useUpdateMyUsername = () => {
  const { mutateAsync } = useMutation<any, UpdateMyUsernameParams, any>(
    http.updateMyUsername,
    {
      onError: (error) => {
        console.log('error' + error);
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useUpdateMyCompanyInvoiceInformation = () => {
  const { mutateAsync } = useMutation<
    any,
    UpdateMyCompanyInvoiceInformationParams,
    any
  >(http.updateMyCompanyInvoiceInformation, {
    onError: (error) => {
      console.log('error' + error);
      throw error;
    },
  });
  return mutateAsync;
};

export const useVerifyNewEmail = () => {
  const { mutateAsync } = useMutation<any, VerifyNewEmailParams, any>(
    http.verifyNewEmail,
    {
      onError: (error) => {
        console.log('error' + error);
        throw error;
      },
    },
  );
  return mutateAsync;
};

export const useGetEmployees = (): UseQueryResult<User[]> => {
  return useQuery([CacheKeys.EMPLOYEES], () => http.getEmployees());
};

export const useAddEmployee = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<any, NewEmployeeParams, any>(
    http.addEmployee,
    {
      onError: (error) => {
        console.log('error' + error);
        throw error;
      },
      onSuccess: () => {
        queryClient.refetchQueries(CacheKeys.EMPLOYEES);
      },
    },
  );
  return mutateAsync;
};

export const useEditEmployee = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<any, EditEmployeeParams, any>(
    http.editEmployee,
    {
      onError: (error) => {
        console.log('error' + error);
        throw error;
      },
      onSuccess: () => {
        queryClient.refetchQueries(CacheKeys.EMPLOYEES);
      },
    },
  );
  return mutateAsync;
};

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<any, EditEmployeeParams, any>(
    http.deleteEmployee,
    {
      onError: (error) => {
        console.log('error' + error);
        throw error;
      },
      onSuccess: () => {
        queryClient.refetchQueries(CacheKeys.EMPLOYEES);
      },
    },
  );
  return mutateAsync;
};
