import { css } from '@emotion/css';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useNavigator } from '../../routing/navigator';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { Layout } from '../../shared/components/layout/Layout';
import { Colors } from '../../shared/styles/colors';
import { h2Style, p2Style } from '../../shared/styles/text';
import {useEffect} from "react";

export const TermsOfSalePage = (): JSX.Element => {
  const navigator = useNavigator();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout.Content className={bodyStyle}>
      <Button
        startIcon={<ChevronLeftIcon className={iconStyle} />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`back`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <h1>Uvjeti prodaje</h1>
      <h2 id={'last-update'}>Last Updated: October 30, 2020</h2>
      <h2>marex-hc.com koristi WSPay za online plaćanja.</h2>
      <p>
        WSPay je siguran sustav za online plaćanje, plaćanje u realnom vremenu,
        kreditnim i debitnim karticama te drugim načinima plaćanja. WSPay kupcu
        i trgovcu osiguravaju siguran upis i prijenos upisanih podataka o
        karticama što podvrđuje i PCI DSS certifikat koji WSPay ima. WSPay
        koristi SSL certifikat 256 bitne enkripcije te TLS 1.2 kriptografski
        protokol kao najviše stupnjeve zaštite kod upisa i prijenosa podataka.
      </p>
      <p>
        Usluge koje se mogu kupiti preko naših <a href="https://marex-hc.com/terms-of-use">partnera</a> su sljedeće:
        <ul>
            <li>Usluga korištenja priključka za električnu energiju</li>
            <li>Usluga korištenja priključka za vodu</li>
        </ul>
      </p>
      <br />
      {/* /terms-of-sale#povrati-sredstava */}
      <h3 id="povrati-sredstava">Povrati sredstava:</h3>
      <p>
            Za povrat preostalih sredstava na računu kupac treba kontaktirati prodavatelja, ovisno o lokaciji na kojoj koristi uslugu.
            <br />
            Uvjeti povrata sredstava razlikuju se ovisno o prodavatelju.
            <br />
            Lista prodavatelja:
            <ul>
                <li> Lučka Uprava Rabac, Obala M. Tita 47c, 52221 Rabac, OIB 43940243528, M: +385 52 872 160</li>
                <li> Grad Omiš, Trg kralja Tomislava 5,21310 Omiš Croatia, OIB 49299622160, M: +385 99 312 4518</li>
                <li> Lučka Uprava Rab, Obala kralja Petra Krešimira IV 7, Rab 51280, Croatia, OIB 17820392036, M: +385 51 725 938</li>
                <li> ČISTOĆA I ZELENILO KONAVLE d.o.o., Bistroće 70, Čilipi, 20215, Croatia, OIB 10165352762 M: +385 20 771 022</li>
            </ul>
      </p>
    </Layout.Content>
  );
};

const bodyStyle = css`
  padding-top: 0;
  > p {
    ${p2Style}
    margin-top:0.7rem;
  }
  > h2 {
    ${h2Style}
    margin-top: 2rem;
  }
  > h2#last-update {
    ${p2Style}
    color: ${Colors.WINDOW_GRAY};
    padding: 1rem 0rem;
    margin-top: 0px;
  }
`;

const backButtonStyle = css`
  padding-top: 0rem;
`;

const iconStyle = css`
  width: 1.5rem;
`;
