const homeRoutes = {
  landing: '/home',
  location: '/home/location',
  locations: '/home/locations',
  pedestal: '/home/pedestal',
  session: '/home/session',
};
const historyRoutes = {
  history: '/history',
  receipt: '/history/receipt',
};
const walletRoutes = {
  wallet: '/wallet',
  walletAddFunds: '/wallet/add-funds',
  walletFundsStats: '/wallet/funds-stats',
};
const accountRoutes = {
  account: '/account',
  vehicles: '/account/vehicles',
  personalInformation: '/account/personal-information',
  changeEmailPage: '/account/change-email-page',
  changeUsernamePage: '/account/change-username-page',
  companyInvoiceInformationPage:
    '/account/change-company-invoice-information-page',
  verifyEmailPage: '/verify-email-page',
  passwordPage: '/account/password',
  personnelPage: '/account/personnel',
};

const otherRoutes = {
  checkEmail: '/authentication/check-email',
  emailSignIn: '/authentication/sign-in/email',
  usernameSignIn: '/authentication/sign-in/username',
  forgotPassword: '/authentication/forgot-password',
  resetPassword: '/reset-password',
  signUp: '/authentication/sign-up',
  payment: '/payment',
  success: '/payment/success',
  paymentError: '/payment/error',
  paymentCancel: '/payment/cancel',
  scan: '/scan',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  termsOfSale: '/terms-of-sale',
};

export const appRoutes = {
  ...homeRoutes,
  ...historyRoutes,
  ...walletRoutes,
  ...accountRoutes,
  ...otherRoutes,
};
